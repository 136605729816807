@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,200;0,400;0,600;1,700&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    display: flex;
    width: 100%;
    min-height: 80vh;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.wrap {
    width: 100%;
    background: #fdfdfd02;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
}

.image {
    width: calc(100% - 560px);
    background-image: url(./logo.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}


.form {
    width: 400px;
    min-height: 90vh;
    display: block;
    background-color: #ffffff;
    padding: 55px 55px 55px;
}

.title {
    width: 100%;
    display: block;
    font-family: 'K2D', sans-serif;
    font-size: 30px;
    color: #000060;
    line-height: 1.2;
    text-align: center;
    padding-top: 45px;
    padding-bottom: 45px;
}

.wrap-input {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 80px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
}

input {
    outline: none;
    border: none;
    overflow: none;
}

.inputName {
    display: block;
    width: 100%;
    height: 100%;
    background: 0 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 1.2;
    padding: 0 26px;
    transition: all .4s;
}

.inputPassword {
    display: block;
    width: 100%;
    height: 100%;
    background: 0 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 1.2;
    padding: 0 26px;
    transition: all .4s;
}



.Name {
    display: block;
    width: 100%;
    height: 100%;
    background: 0 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 1.2;
    padding: 0 26px;
    transition: all .4s;
}

.RepeatinputPassword {
    display: block;
    width: 100%;
    height: 100%;
    background: 0 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 1.2;
    padding: 0 26px;
    transition: all .4s;
}

.box {
    position: relative; /* adiciona posição relativa ao container */
    margin-bottom: -1px;
    -webkit-appearance: none; /* remove o estilo padrão do checkbox do WebKit */
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #ddd; /* adiciona uma borda para melhorar a aparência */
    margin-right: 10px;
  }
  
  .box:checked::after {
    content: "";
    position: absolute; /* adiciona posição absoluta à checkbox marcada */
    top: 50%; /* posiciona a checkbox marcada no centro vertical */
    left: 50%; /* posiciona a checkbox marcada no centro horizontal */
    transform: translate(-50%, -50%); /* centraliza a checkbox marcada */
    width: 10px;
    height: 10px;
    background-color: rgb(17, 0, 255);
    border-radius: 0%;
   
} 

.su
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: 'K2D', sans-serif;
    font-size: 25px;
    color: #ff0000;  
    animation: color-animation 0.5s infinite;
}
@keyframes color-animation {
    0% {
      color: #001aff;
    }
    50% {
      color: #ff0000;
    }
    100% {
      color: #55ff00;
    }
  }

.focus-input {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #6675df;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all .4s;
    transform: scaleX(1.1) scaleY(1.3);
}
.flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-bottom: 32px;
    padding-top: 3px;
    }
    
    a {
    margin: 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #777;
    }
    
    .btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    }
    
    .btn-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 80%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #fff;
    background: #000060;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #fff;
    transition: background-color 0.3s ease-in-out;
    }
    
    .btn-btn:hover {
    border: 1px solid #000060;
    background: transparent;
    color: #000060;
    }
    


    .exit {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        }
        
        .exit-exit {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        width: 40%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #fff;
        background: #ff0000;
        font-family: 'K2D', sans-serif;
        font-size: 18px;
        color: #fff;
        transition: background-color 0.3s ease-in-out;
        }
        
        .exit-exit:hover {
        border: 1px solid #ff0000;
        background: transparent;
        color: #ff0000;
        }

        