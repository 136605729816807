
.main {

  background-color: #ed6b6b;
  align-items: center;
  justify-content: center;
  width: 100%;
 height: 100%;
}


/* const renderUsers */
.division {
  display: inline-grid;
  place-content: center;
  grid-template-areas: 1fr 1fr;
  align-items: center;
  justify-items: center;
  align-content: center;
  
  
}

.user-box {
  display: inline-grid;
  grid-column: 1/2;
  grid-template-columns: 50% 50%;
  align-items: flex-start;
  justify-content: center;
  font-family: 'K2D', sans-serif;
  font-style: italic;
  background-color: #fff;
  width: 500px;
  padding: 25px;
  margin: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.div1,
.div2 {
  display: flex;
  flex-direction: column;
}

.div2 {
  align-items: flex-end;
  padding-top: 40px;
}

.namebox {
  padding: 10px;
  border: 1px solid;
  border-radius: 20px;
}

.blue {
  color: #6699ff;
}

.red {
  color: #ff6666;
}

button {
  border: none;
  font-size: 20px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.edit-button {
  background-color: #6699ff;
  color: #fff;
  width: 10rem;
}

.edit-button:hover {
  background-color: #fff;
  color: #6699ff;
  border: 1px solid #6699ff;
  width: 12rem;
}

.delete-button {
  background-color: #ff6666;
  color: #fff;
  margin-right: 10px;
  width: 6rem;
}

.delete-button:hover {
  background-color: #fff;
  color: #ff6666;
  border: 1px solid #ff6666;
  width: 8rem;
}


/* const pageNumbers */

.title {
  /* color: #0f0f0f; */
  font-size: 32px;
}

.TitleBox {
  display: flex;
  flex-direction: column;
  padding: -80px -80px;
  height: 100px;
  /* align-items: center;
  justify-items: center;
  justify-content: center; */
}

/* Input de pesquisa de Usuários*/

.search-input {
  display: flex;
  flex-direction: column;
}

.searchBox {
  color: #69f;
	margin: 0 auto;
  padding: 0.5rem 0.5rem;
  border-radius: 50px;
  background: rgb(255, 255, 255) url(img/3148967-200.png) center no-repeat;
  background-size: 30px 30px;
  border: none;
  width: 16%;
  transition: all 1s ease-in-out;
  text-transform:none;
}

.searchBox:hover {
  font-family: 'K2D', sans-serif;
  font-size: 1.2rem;
  background: #fff;
  color: #6699ff;
	margin: 0 auto;
  padding: 0.25rem 0.02rem;
  border-radius: 50px;
  width: 100%;
  display: block;
  text-align: center;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  place-content: "";
}

.searchInput {
   display: flex;
   position: absolute;
  top: 80px;
  right: 25px;
} 

.page-number {
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.btn {
  padding: 20px 0;
}

.back {
  background-color: #4e4e4e;
  color: #fff;
  border: none;
  font-size: 18px;
  border-radius: 15px;
  padding: 10px 10px;
  cursor: pointer;
  width: 12rem;
}

.back:hover {
  background-color: #717171;
  width: 15rem;
  font-size: 20px;
}

.modal-txt {
  font-family: 'K2D', sans-serif;
  display: flex;
  justify-content: center;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cancel-modal-button {
  margin: 0 10px;
}

.delete-modal-button {
  margin: 0 10px;
  color: #ff6666;
}

/* No Usability */

.pagination {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  display: flex;
  justify-content: flex-end;
}

.active {
  color: #000;
  font-weight: bold;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pagination-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-link {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #dee2e6;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #f8f9fa;
}
