@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,200;0,400;0,600;1,700&display=swap');

.backup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f2f2f2;
  }
  
  .backup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  
  .backup-logo {
    width: 200px;
    margin-bottom: 16px;
  }
  
  .backup-title {
    font-family: 'K2D', sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px;
    color: #26255a;
  }

  
  .backup-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .backup-input-container {
    font-family: 'K2D', sans-serif;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: #26255a;
  }
  
  .backup-input-label {
    
    margin-bottom: 8px;
    font-weight: bold;
    color: #26255a;
  }
  
  .backup-input {
    color: #26255a;
    font-family: 'K2D', sans-serif;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .backup-dropzone {
    font-family: 'K2D', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 96px;
    border: 2px dashed #26255a;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  
  .backup-dropzone-text {
    color: #666;
  }
  
  .backup-button {
    font-family: 'K2D', sans-serif;
    padding: 12px 16px;
    background-color: #26255a;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .backup-button:hover {
    
    background-color: #26255a;
  }
  
  .backup-progress-container {
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  
  .backup-progress-bar 
  {
    font-family: 'K2D', sans-serif;
    height: 8px;
    background-color: #26255a;
    border-radius: 4px;
  }
  
  .backup-progress-text {
    font-family: 'K2D', sans-serif;
    margin-left: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #26255a;
  }
  
  /* adição das cores em azul e branco conforme solicitado */
  
  body {
    background-color: #f2f2f2;
  }
  
  h2 {
    color: #26255a;
  }
  
  label {
    color: #26255a;
  }
  
  input[type="file"] {
    border-color: #26255a;
  }
  
  button[type="submit"] {
    background-color: #26255a;
  }
  
  button[type="submit"]:hover {
    background-color: #26255a;
  }
  
  .backup-dropzone {
    border-color: #26255a;
  }
  
  .backup-dropzone-text {
    color: #666;
  }
  
  .backup-progress-bar {
    background-color: #26255a;
  }
  
  .backup-progress-text {
    color: #26255a;
  }

.exitButtonDiv
{
  display: flex;
  align-items: center;
  justify-content: center;
}

  .backB
  {
    font-family: 'K2D',sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    background-color: red;
    color:white;
    animation-name: b;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    
  }
  @keyframes b{
    0%{
      color: #26255a;
    }
    100%{
      color: rgb(255, 255, 255);
    }
  }

  .backup-loading {
    font-family: 'K2D', sans-serif;
    font-size: 805pxs;
   
    color: #600d5d;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
  }

.confLogo
{
   margin-left: 100%;
  width: 5%;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.confLogo:hover
{
  background-color: #26255a66;
  border-radius: 100%;
  
}

@keyframes rotate{
  0%
  {
    motion-rotation: auto;
  }
  100%
  {
    motion-rotation: reverse;
  }
}

.pathText
{
  display: flex;
  font-family: 'K2D',sans-serif;
  justify-content:flex-start;
}

.pathtxtarea
{
  background-color: #ffffff;
  
  display: flex;
 margin-left: -50%;
  width: auto;
  text-align: start;
  font-family: 'K2D', sans-serif;
  margin-top: -4%;
  font-size: 15px;
}
.pbutton
{
display: flex;
align-items: center;
margin-left: 110%;
margin-top: -15%;

}
.backup-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.backup-progress-container {
  width: 100%;
  height: 20px;
  background-color: #f3f3f3;
  border: 1px solid #bbb;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}

.backup-progress-bar {
  height: 100%;
  background-color: #0077b6;
  position: absolute;
  left: 0;
  top: 0;
}

.backup-progress-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #000;
}
