@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,200;0,400;0,600;1,700&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    display: flex;
    width: 100%;
    min-height: 80vh;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.wrap {
    width: 100%;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
}

.image {
    width: calc(100% - 560px);
    background-image: url(./logo.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.form {
    width: 400px;
    min-height: 90vh;
    display: block;
    background-color: #f7f7f7;
    padding: 55px 55px 55px;
}

.title {
    width: 100%;
    display: block;
    font-family: 'K2D', sans-serif;
    font-size: 30px;
    color: #333;
    line-height: 1.2;
    text-align: center;
    padding-top: 45px;
    padding-bottom: 45px;
}

.wrap-input {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 80px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
}

input {
    outline: none;
    border: none;
    overflow: none;
}

.inputName {
    display: block;
    width: 100%;
    height: 100%;
    background: 0 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 1.2;
    padding: 0 26px;
    transition: all .4s;
}

.inputPassword {
    display: block;
    width: 100%;
    height: 100%;
    background: 0 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 1.2;
    padding: 0 26px;
    transition: all .4s;
}

.focus-input {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #6675df;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all .4s;
    transform: scaleX(1.1) scaleY(1.3);
}
.flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-bottom: 32px;
    padding-top: 3px;
    }
    
    a {
    margin: 0;
    font-family: 'K2D', sans-serif;
    font-size: 18px;
    color: #777;
    }
    

    .createLogin
    {
        color: #157cb4;
        font-family: 'k2D', sans-serif;
        justify-content: center;
        margin-top: 35%;
        margin-left: 30%;
        padding-bottom: 32px;
        
    }
    b {
        margin: 0;
        font-family: 'K2D', sans-serif;
        font-size: 18px;
        color: #007bff;
        }
    

    .btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    }
    .btn-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        width: 80%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #fff;
        background: #000060;
        font-family: 'K2D', sans-serif;
        font-size: 18px;
        color: #fff;
        transition: background-color 0.3s ease-in-out;
        }
        
        .btn-btn:hover {
        border: 1px solid #000060;
        background: transparent;
        color: #000060;
        }



    
        .cadbtn {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            }
            .cadbtn-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 20px;
                width: 80%;
                height: 40px;
                border-radius: 10px;
                border: 1px solid #fff;
                background: #000060;
                font-family: 'K2D', sans-serif;
                font-size: 18px;
                color: #fff;
                transition: background-color 0.3s ease-in-out;
                }
                
                .cadbtn-btn:hover {
                border: 1px solid #000060;
                background: transparent;
                color: #000060;
                }
        
                
        .bkbtn {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            }
            .bkbtn-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 20px;
                width: 80%;
                height: 40px;
                border-radius: 10px;
                border: 1px solid #fff;
                background: #000060;
                font-family: 'K2D', sans-serif;
                font-size: 18px;
                color: #fff;
                transition: background-color 0.3s ease-in-out;
                }
                
                .bkbtn-btn:hover {
                border: 1px solid #000060;
                background: transparent;
                color: #000060;
                }



                   
                
        .viewbtn {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            }
            .viewbtn-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 20px;
                width: 80%;
                height: 40px;
                border-radius: 10px;
                border: 1px solid #fff;
                background: #000060;
                font-family: 'K2D', sans-serif;
                font-size: 18px;
                color: #fff;
                transition: background-color 0.3s ease-in-out;
                }
                
                .viewbtn-btn:hover {
                border: 1px solid #000060;
                background: transparent;
                color: #000060;
                }