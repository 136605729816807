.main {
  background-color: #efeded;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}

.division {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.user-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'K2D', sans-serif;
  font-style: italic;
  background-color: #fff;
  width: 95%;
  padding: 15px 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.edit-button {
  background-color: #6699ff;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.edit-button:hover {
  background-color: #fff;
  color: #6699ff;
  border: 1px solid #6699ff;
}

.delete-button {
  background-color: #ff6666;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.delete-button:hover {
  background-color: #fff;
  color: #ff6666;
  border: 1px solid #ff6666;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.page-link {
  background-color: #2e27f0;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.page-link:hover {
  background-color: #1e1a7a;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.page-link:active {
  background-color: #434289;
}

@media (max-width: 768px) {
  .user-box {
    flex-direction: column;
  }
  .edit-button, .delete-button {
    margin-top: 10px;
  }
}
.pagination-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-link {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #dee2e6;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #f8f9fa;
}